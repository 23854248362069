import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  useNavigate,
  useParams,
  useLocation,
  Outlet,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import PSLogo from '../../assets/images/Poweredbysoopra.png';
import Credit from '../../assets/images/credit.png';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import NotificationMenu from '../NotificationMenu/NotificationMenu';
import { useAuth } from '../../hooks/useAuth';
import { auth } from '../../utils/firebase';
import ProfileCreatedDialog from '../userProfile/profileCreatedDialog';
import LoginButton from './LoginButton';

const notificationsEnabled =
  process.env.REACT_APP_ENABLE_NOTIFICATIONS === 'true';

const monetizationEnabled =
  process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

type CreditsContext = {
  credits: number,
  setCredits: React.Dispatch<React.SetStateAction<number>>,
  maxCredits: number,
  setMaxCredits: React.Dispatch<React.SetStateAction<number>>,
}

const Navbar = () => {
  const navigate = useNavigate();
  const { userName } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {
    authUser,
    isAnonymousUser,
    showProfileCreated,
    setShowProfileCreated,
  } = useAuth();
  const queryParam = parseInt(searchParams?.get('step'));
  const [credits, setCredits] = useState<number>(0);
  const [maxCredits, setMaxCredits] = useState<number>(0);
  const [prevPath, setPrevPath] = useState<string>('');
  const locationRef = useRef(location.pathname);

  const routesWithoutBackButton = [
    '/',
    '/signin',
    '/verify-email',
    '/create-profile',
    '/creators',
    '/courses',
  ];
  const routesWithoutSideBar = ['/verify-email', '/create-profile'];
  const routesWithoutNotification = ['/verify-email', '/create-profile'];
  const routesWithoutCredits = ['/courses', '/learn', '/createCourse', '/CreateCourseIntro', '/CreateAssessments', '/CreateCourseBanner', '/CoursePreview', '/signin', '/signin-email', '/verify-email', '/forgot-password', '/signup'];
  const routesWhichRedirectToCreateCourse = ['/CoursePreview', '/CreateCourseBanner', '/CreateAssessments', '/CreateCourseIntro'];

  useEffect(() => {
    if (authUser && authUser.credits) {
      setCredits(authUser.credits.reduce((acc, curr) => acc + curr["remaining"], 0));
      setMaxCredits(authUser.credits.reduce((acc, curr) => acc + curr["maxAmount"], 0));
    } else {
      setCredits(0);
      setMaxCredits(0);
    }
  }, [authUser]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath !== locationRef.current) {
      setPrevPath(locationRef.current);
      locationRef.current = currentPath;
    }
  }, [location.pathname]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '50px',
          padding: '0.4rem',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1100,
          backgroundColor: '#fff',
          boxShadow: '0 0px 4px rgba(0,0,0,0.1)',
        }}
      >
        <Box className={"back-button-box"} sx={{ minWidth: 76, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {routesWithoutBackButton.includes(location.pathname) ? (
            <></>
          ) : location.pathname === `/creators` || isAnonymousUser ? (
            <IconButton
              id='button-back'
              sx={{zIndex: 1101,}}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon
                sx={{
                  height: '24px',
                  width: '24px',
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              id='button-back'
              sx={{zIndex: 1101,}}
              onClick={() => {
                const hostname = window.location.hostname;
                if (hostname === 'sambatv.mimio.ai' && (location.pathname.startsWith('/sambatv'))) {
                  navigate('/aipersona');
                } else if (hostname === 'stanford.soopra.ai') {
                  navigate('/creators');
                } else if (location.pathname === `/${userName}`) {
                  navigate('/creators');
                } else if (location.pathname === `/${userName}/chat`) {
                  if (prevPath === `/${userName}`) {
                    navigate(`/${userName}`);
                  } else {
                    navigate('/creators');
                  }
                } else if (location.pathname === '/train') {
                  navigate(`/${authUser?.userName}`);
                } else if (
                  location.pathname === '/creator-onboarding' &&
                  queryParam === 1
                ) {
                  navigate('/creator-onboarding?step=0');
                } else if (location.pathname === '/signin-email') {
                  navigate('/signin');
                } else if (location.pathname.startsWith('/courses')) {
                  navigate('/courses');
                } else if (routesWhichRedirectToCreateCourse.some((route) => location.pathname.startsWith(route))) {
                  navigate('/createCourse');
                } else {
                  navigate(-1);
                }
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: '#000000',
                  height: '24px',
                  width: '24px',
                }}
              />
            </IconButton>
          )}
        </Box>
        <Box
          className={"logo-box"}
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            padding: '0.6rem',
            position: 'absolute',
            left: 0,
            right: 0,
            '& img': {
              cursor: 'pointer',
              // maxHeight: { xs: '50%', sm: '30%', md: '20%', lg: '10%' },
              // maxWidth: { xs: '60%', sm: '30%', md: '20%', lg: '12%' },
              height: '35px',
            }
          }}
        >
          <img
            src={window.location.hostname === 'stanford.soopra.ai' ? PSLogo : Logo}
            alt='logo'
            onClick={() => {
              // Determine the current hostname
              const hostname = window.location.hostname;
              const pathname = location.pathname;

              // Scroll to the top if on the creator page
              if (pathname === `/creators`) {
                window.location.reload();
              } else if (hostname === 'sambatv.mimio.ai' && (pathname.startsWith('/sambatv') || pathname === '/dashboard')) {
                navigate('/aipersona');
              } else if (hostname === 'stanford.soopra.ai' && (pathname === '/dashboard')) {
                navigate('/creators');
              } else if (authUser) {
                // For other subdomains or paths, navigate based on authUser presence
                navigate('/creators');
                window.scrollTo(0, 0);
              } else {
                // If not authenticated, navigate to login
                navigate('/login');
              }
            }}
          />
        </Box>
        <Box sx={{ minWidth: 76, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {
            monetizationEnabled && (location?.pathname !== "/") &&
            !window.location.hostname.includes('stanford.soopra.ai') &&
            (routesWithoutCredits.some((route) => location?.pathname.startsWith(route)) ? <></> :
              <Box
                className={"credits-box"}
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '0.6rem',
                  marginRight: '2rem',
                    '& img': {
                      marginRight: '0.5rem',
                      height: '24px',
                    }
                }}
              >
                <img src={Credit} alt='soopra-credit' />
                <Typography
                  component='p'
                  variant='body2'
                  sx={{
                    fontSize: '14px', // Change the font family
                    color: 'black',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    padding: '3px 0px',
                  }}
                >
                  {credits}
                </Typography>
              </Box>
            )
          }

          <Box>
            {routesWithoutNotification.includes(location.pathname) ? (
              <></>
            ) : (
              notificationsEnabled &&
              !isAnonymousUser &&
              auth.currentUser && <NotificationMenu />
            )}
          </Box>

          <Box>
            {routesWithoutSideBar.includes(location.pathname) ? (
              <></>
            ) : (
              authUser ? <HamburgerMenu /> : <LoginButton />
            )}
          </Box>
        </Box>
      </Box>
      <ProfileCreatedDialog
        authUser={authUser}
        showProfileCreated={showProfileCreated}
        setShowProfileCreated={setShowProfileCreated}
      />

      <Outlet context={{credits, setCredits, maxCredits, setMaxCredits}}/>
    </div>
  );
};

export default Navbar;

export function useCreditsContext() {
  return useOutletContext<CreditsContext>();
}
