import * as React from 'react';
import { Suspense, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CircularProgress from '@mui/material/CircularProgress';
import CopyToClipboardButton from '../../component/CopyClipBoardButton';
import { UserFormType } from '../creatorOnBoarding/signup.types';
import SocialMediaLinks from './socialMediaLinks';
import ChatButtonIcon from '../../svg/ChatButtonIcon';
import FillBulbIcon from '../../svg/FillBulbIcon';
import BulbIcon from '../../svg/BulbIcon';
import BadgeIcon from '../../svg/BadgeIcon';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import { grey } from '@mui/material/colors';
import { useAuth } from '../../hooks/useAuth';
import UserCourseData from './userCourseData';
import { Timestamp } from 'firebase/firestore';

interface UserProfileDataType {
  authUser: UserFormType;
  profileData: UserFormType;
}
export default function UserProfileData(props: UserProfileDataType) {
  const {
    documentId = '',
    fullName = '',
    profilePhotoURL = '',
    userName = '',
    shareLink = '',
    index = '',
    shortBio = '',
    bannerPhotoUrl = '',
    total_fans = 0,
    total_messages = 0,
    voiceId,
    subscription,
  } = props?.profileData || {};

  const { documentId: authDocumentId = '' } = props?.authUser || {};
  const { authUser } = useAuth();

  const [premiumAccess, setPremiumAccess] = React.useState<boolean>(false);

  useEffect(() => {
    if (subscription?.premium_price_id) {
      let access = false;
      let premiumKey = subscription?.premium_price_id;
      if (documentId === authDocumentId) {
        access = true;
      } else if (authUser?.premiumPurchases) {
        let currentTimeStamp = Timestamp.fromDate(new Date());
        if (authUser.premiumPurchases[premiumKey]){
          // check if not expired
          if (authUser.premiumPurchases[premiumKey] > currentTimeStamp){
            access = true;
          }
        }
      }
      if (premiumAccess !== access) setPremiumAccess(access);
    }
  }, [authUser, subscription]) //eslint-disable-line react-hooks/exhaustive-deps

  const FrankSortinoFeatureButtons = React.lazy(() =>
    import('../customUIElements/FrankSortino').then((module) => ({
      default: module.FrankSortinoFeatureButtons,
    }))
  );

  const PremiumPurchaseButton = React.lazy(() =>
    import('../Purchases/premiumPurchaseButton')
  )

  const isStanfordUrl = window.location.href.startsWith('https://stanford.soopra.ai');

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box maxHeight='250px' height='100%' width='100%'>
        <Box display='flex' bgcolor='grey.200'>
          {bannerPhotoUrl ? (
            <img
              style={{
                alignSelf: 'stretch',
                width: '100%',
                overflow: 'hidden',
                height: '130px',
                flexShrink: '0',
                objectFit: 'cover',
              }}
              alt=''
              src={bannerPhotoUrl}
            />
          ) : (
            <Box height='130px' width='100%' bgcolor='mainBlue.main'></Box>
          )}
        </Box>
        <Box display='flex' px={2.5}>
          <Box mt={-4} position="relative">
            <Avatar
              src={profilePhotoURL}
              alt={fullName}
              sx={{
                width: 116,
                height: 116,
                border: '2px solid #fff',
                fontSize: '3.625rem'
              }}
            >{fullName ? fullName[0] : ''}</Avatar>
            {voiceId &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  width: 23,
                  height: 23,
                  borderRadius: '50%',
                  bottom: 5,
                  right: 7,
                  backgroundColor: 'white',
                  borderColor: grey[500] + '!important',
                  border: 'solid 1px'
                }}
              >
                <GraphicEqIcon
                  sx={{
                    width: 15,
                    height: 15,
                    color: `${grey[500]}`
                  }}
                />
              </Box>
            }
          </Box>
          <Box
            display='flex'
            justifyContent='flex-start'
            width='100%'
            gap={4}
            ml={4}
            mt={2}
            textAlign='center'
          >
            <Box display='flex' flexDirection='column'>
              <Typography variant='subtitle1' fontWeight={700}>
                {total_fans || 0}
              </Typography>
              <Typography variant='body1' fontWeight={400}>
                Fans
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='subtitle1' fontWeight={700}>
                {total_messages || 0}
              </Typography>
              <Typography variant='body1' fontWeight={400}>
                Messages
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box width='100%' mt={1}>
        <Box
          display='flex'
          justifyContent='flex-start'
          textAlign='left'
          flexDirection='column'
          px={2.5}
        >
          <Box display='flex' alignItems='center'>
            <Typography component='h6' variant='h6' fontWeight={600}>
              {fullName}
            </Typography>
            {
              subscription?.premium_price_id &&
              <VerifiedIcon sx={{ml: 1, fill: '#3EACEF'}}/>
            }
          </Box>
          <Typography
            component='h6'
            variant='body1'
            fontWeight={400}
            color={'#000'}
          >
            {`@${userName}`}
          </Typography>
          <Typography
            component='p'
            variant='body2'
            color={'#000'}
            sx={{ mt: 1 }}
          >
            {shortBio}
          </Typography>
        </Box>

        <Box mt={3} px={2.5}>
          <SocialMediaLinks profileData={props?.profileData} />
        </Box>

        <Box width='100%'>
          {!index && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              textAlign='center'
              flexDirection='column'
              gap={2}
              my={3}
            >
              <Typography
                variant='body1'
                fontWeight={500}
                sx={{
                  justifyContent: 'center',
                  padding: 0.8,
                  borderRadius: 'none',
                  width: '100%',
                  maxWidth: '280px',
                }}
              >
                Your personality is being created! It may take a few minutes.
              </Typography>
              <CircularProgress
                size={30}
                sx={{
                  color: 'grey.600',
                }}
              />
            </Box>
          )}

          {/* Premium subscription button */}
          {
            (subscription?.premium_price_id && !premiumAccess) ?
            <Suspense >
              <Box p={2.5}>
                <PremiumPurchaseButton requiredPremiumKey={subscription.premium_price_id} redirectURL={`/${userName}/chat`}/>
              </Box>
            </Suspense> : <></>
          }

          <Box display='flex' flexDirection='row' gap={2} px={2.5}>
            {/* Chat Button */}
            <Link
              id={index ? `href-chat-${userName}` : ''}
              to={index ? `/${userName}/chat` : ''}
              aria-disabled={!index}
              style={{
                cursor: index ? 'pointer' : 'default',
                flex: 1,
              }}
            >
              <Button
                id='button-chat'
                variant='contained'
                fullWidth
                sx={{
                  py: 1,
                  mt: '12px',
                  '&.MuiButton-root.Mui-disabled': {
                    color: '#D9D9D9',
                    border: '1px solid #D9D9D9',
                    '&. MuiButton-startIcon': {
                      color: '#D9D9D9',
                    },
                  },
                }}
                disabled={!index}
                size='small'
                startIcon={<ChatButtonIcon />}
              >
                {authDocumentId === documentId ? 'Chat' : 'Chat'}
              </Button>
            </Link>

            {/* Talk Button */}
            {voiceId ? (
              <Link
                id={`href-voice-${userName}`}
                to={index ? `/${userName}/chat` : ''}
                state={{useVoiceChat: true}}
                style={{
                cursor: index ? 'pointer' : 'default',
                flex: 1,}}
              >
                <Button
                  id={`button-voice-${userName}`}
                  variant="outlined"
                  sx={{
                    py: 1,
                    mt: '12px',
                    border: '1px solid #009CDB !important',
                    color: '#009CDB !important',
                    '&.MuiButton-root:hover': {
                      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                    },
                  }}
                  fullWidth
                  size='small'
                  startIcon={<GraphicEqIcon />}
                >
                  Talk
                </Button>
              </Link>
            ) : (
              <Box sx={{flex: 1}}>
                <Button
                  id={`button-voice-disabled-${userName}`}
                  variant='outlined'
                  fullWidth
                  sx={{
                    py: 1,
                    mt: '12px',
                    backgroundColor: 'white !important',
                    border: '1px solid #009CDB',
                    color: grey[300] + '!important',
                    borderColor: grey[300] + '!important',
                    '&.MuiButton-root.Mui-disabled': {
                      color: '#D9D9D9',
                      border: '1px solid #D9D9D9',
                      '&. MuiButton-startIcon': {
                        color: '#D9D9D9',
                      },
                    },
                  }}
                  size='small'
                  startIcon={<GraphicEqIcon />}
                >
                  Talk
                </Button>
              </Box>
            )}
          </Box>
          {
            ((userName === 'srinivasjaini' || userName === 'sortino-chat') && !isStanfordUrl) ?
              <Suspense>
                <FrankSortinoFeatureButtons linkTo={`/${userName}/chat`}/>
              </Suspense> : <></>
          }
          <Box width='100%' mt={2}>
            {authDocumentId === documentId && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                gap={2}
                px={2.5}
              >
                <Link id={`href-edit-info-${userName}`} to={`/${userName}/update`} style={{ flex: 1 }}>
                  <Button
                    id={`button-edit-info-${userName}`}
                    variant='outlined'
                    fullWidth
                    startIcon={<EditOutlinedIcon />}
                    size='small'
                    sx={{ py: 1 }}
                  >
                    Update
                  </Button>
                </Link>
                <Box style={{ flex: 1 }}>
                  <CopyToClipboardButton
                    copyLink={`${shareLink}`}
                    buttonProps={{
                      disabled: !index,
                      fullWidth: true,
                      sx: {
                        py: 1,
                        '&.MuiButton-root.Mui-disabled': {
                          color: '#D9D9D9',
                          border: '1px solid #D9D9D9',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            )}

            <UserCourseData authUser={authUser} profileData={props?.profileData}/>

            {authDocumentId === documentId && (
              <Box
                mt={4}
                bgcolor='#FAFAFA'
                display='flex'
                flexDirection='column'
                width='100%'
                py={3}
                px={2.5}
                sx={{
                  borderTop: '1px solid #D9D9D9',
                  borderBottom: '1px solid #D9D9D9',
                }}
                gap={1}
              >
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box display='flex' alignItems='center' gap={1}>
                    <BulbIcon />
                    <Typography fontWeight='600' color='#000' variant='body1'>
                      Training Progress
                    </Typography>
                  </Box>
                  <BadgeIcon fontSize='20px' />
                </Box>
                <Typography lineHeight='normal' color='#000' variant='body2'>
                  Training your AI Persona will help give the most authentic
                  experience. Complete training to earn your verification badge.
                </Typography>
                <Link
                  id={index ? `href-train-${userName}` : ''}
                  to={index ? `/train` : ''}
                  aria-disabled={!index}
                  style={{
                    cursor: index ? 'pointer' : 'default',
                  }}
                >
                  <Button
                    id='button-train'
                    fullWidth
                    disabled={!index}
                    variant='contained'
                    size='small'
                    sx={{
                      py: 1,
                      mt: '12px',
                      '&.MuiButton-contained': {
                        fontWeight: 400,
                      },
                    }}
                    startIcon={<FillBulbIcon fontSize='22px' />}
                  >
                    Train My AI Persona
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
