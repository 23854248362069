import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate } from "react-router-dom";
import StripeCheckoutForm from '../StripeCheckoutForm';
import { useAuth } from '../../hooks/useAuth';
import { getCourseInfo, getPaymentsHistory } from '../../utils/api';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EventIcon from '@mui/icons-material/Event';
import { grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { useCreditsContext } from '../navbar';
import GenericModal from '../../component/GenericModal';
import timeoutImage from '../../assets/images/timeout.png';
import { STRIPE_PRODUCT_DISPLAY_NAMES } from '../../config/const';

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';
const enableCourses = process.env.REACT_APP_SHOW_MY_COURSE === 'true';

const Purchases: React.FC = () => {
  const { authUser, authLoading } = useAuth();
  const [ paymentsHistory, setPaymentsHistory] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ lastSub, setLastSub ] = useState(null);
  const { credits, maxCredits } = useCreditsContext();
  const [ isSubscribed, setIsSubscribed ] = useState(false);
  const [ stripeOpen, setStripeOpen ] = useState(false);
  const [ stripeItem, setStripeItem ] = useState(null);
  const [ showGenericModal, setShowGenericModal ] = useState(false);
  const [ genericModalData, setGenericModalData ] = useState(null);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const fullyLoaded = useRef<boolean>(false);
  const bottom = useRef(null);

  useEffect(() => {
    try {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadNextPage();
        }
      });
      observer.observe(bottom.current);

      return () => {
        observer.disconnect();
      };
    }
    catch (err) {
      // To-do: Error handling when observer fails
    }
  }, [paymentsHistory]) //eslint-disable-line react-hooks/exhaustive-deps

  const loadNextPage = () => {
    if (!isLoading && authUser && !fullyLoaded.current) {
      setIsLoading(true);
      getPaymentsHistory(authUser.documentId, page+1, 10)
        .then(res => res.json())
        .then(async (data) => {
          setPage(data.page)
          await addCourseDataToPaymentHistory(data?.data || [])

          setPaymentsHistory(paymentsHistory.concat(data.data))

          if (!isSubscribed){
            for (let transaction of data.data){
              if (transaction.item === "basic_soopra_subscription"){
                let renewalDate = new Date(transaction.renewalDate);
                if (renewalDate > new Date()){
                  setIsSubscribed(true);
                }
                setLastSub(transaction)
                break;
              }
            }
          }
          if (!data.data.length) {
            fullyLoaded.current = true;
          }
          setIsLoading(false);
        })
        .catch(err => {
          // To-do: error handling for failed payment history loading
          console.log(err)
          fullyLoaded.current = true;
          setIsLoading(false);
        })
    }
  }

  const addCourseDataToPaymentHistory = async (history: [any]) => {
    return new Promise(resolve => {
      let finished = 0;

      let completePromise = () => {
        finished++;
        if(finished === history.length) {
          resolve(history)
        }
      };

      history.forEach((entry: any) => {
        if (entry.item_id) {
          getCourseInfo(entry.item_id)
            .then(res => {
              if (res.ok) {
                return res.json();
              }
            })
            .then(courseData => {
              entry["course_info"] = courseData;
            })
            .catch(err => {
              console.log('Failed to fetch course')
            })
            .finally(completePromise)
        } else {
          finished++;
        }
      })
      if (finished === history.length) {
        resolve(history)
      }
    });
  }

  let SubscriptionStatus = () => {
    let status = lastSub?.status;
    let renewalDate = "No active subscription"
    if (lastSub?.status === 'paid' && lastSub?.renewalDate) {
      renewalDate = new Date(lastSub?.renewalDate).toDateString();
    }

    return (
      <>
        <Box>
          {(status === 'paid') ?
            <Typography component='h6' variant='h6' fontWeight={600}  sx={{
              fontSize: '14px', // Change the font family
              color: 'green',
              height: '1.5em',
              paddingRight: '5px'
            }}>
              Subscription is active
            </Typography> :
            <Typography component='h6' variant='h6' fontWeight={600}  sx={{
              fontSize: '14px', // Change the font family
              color: 'red',
              height: '1.5em',
              paddingRight: '5px'
            }}>
              Subscription is inactive
            </Typography>
          }
          <Box sx={{
            paddingTop: '20px',
            paddingBottom: '0px',
            display: 'flex'
          }}>
            <Typography component='h6' variant='h6' fontWeight={600}  sx={{
              fontSize: '14px', // Change the font family
              color: 'black',
              height: '1.5em',
              paddingRight: '5px'
            }}>
              Next charge date:
            </Typography>
            <Typography component='h6' variant='h6' fontWeight={400}  sx={{
              fontSize: '14px', // Change the font family
              color: 'black',
              height: '1.5em',
              paddingRight: '5px'
            }}>
              {renewalDate}
            </Typography>
          </Box>
          <Typography component='h6' variant='h6' fontWeight={400}  sx={{
              fontSize: '14px', // Change the font family
              color: 'black',
              height: '1.5em',
              paddingRight: '5px'
            }}>
              {status === 'paid' ? "$1.99 per month" : " "}
          </Typography>
          <Typography component='h6' variant='h6' fontWeight={400}  sx={{
              fontSize: '14px', // Change the font family
              color: 'gray',
              height: '1.5em',
              paddingRight: '5px'
            }}>
              {status === 'paid' ? "plus applicable taxes" : " "}
          </Typography>
        </Box>
        <Box className={"mobile-margin-top"} sx={{
          display: 'flex',
        }}>
          <Typography component='h6' variant='h6' fontWeight={600}  sx={{
            fontSize: '14px', // Change the font family
            color: 'black',
            height: '1.5em',
            paddingRight: '5px'
          }}>
            Credits Available:
          </Typography>
          <Typography component='h6' variant='h6' fontWeight={400}  sx={{
            fontSize: '14px', // Change the font family
            color: 'black',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            height: '1.5em',
          }}>
            {credits + '/' + maxCredits}
          </Typography>
        </Box>
      </>
    )
  };

  let PaymentMethod = () => {
    let type = lastSub?.details?.type;
    let methodStr = ""
    if (type === 'card'){
      methodStr = "Credit Card - "
      methodStr += lastSub?.details?.brand + " "
      methodStr += "*" + lastSub?.details?.last4
    }

    let frequency = lastSub?.billingFrequency;
    let freqStr = ""
    if (frequency === 'month'){
      freqStr = "Monthly"
    }
    return <>
      <Box sx={{display: 'flex'}}>
        <CreditCardIcon sx={{marginRight: '10px'}}/>
        <Box>
          <Typography component='h6' variant='h6' fontWeight={600}  sx={{
            fontSize: '14px', // Change the font family
            color: 'black',
            height: '1.5em',
            paddingRight: '5px'
          }}>
            Payment Method
          </Typography>
          <Typography component='h6' variant='h6' fontWeight={400}  sx={{
            fontSize: '14px', // Change the font family
            color: 'black',
            height: '1.5em',
            paddingTop: '5px',
            paddingRight: '5px',
            textTransform: 'capitalize'
          }}>
            {methodStr}
          </Typography>
        </Box>
      </Box>
      <Box className={"mobile-margin-top"} sx={{display: 'flex'}}>
        <EventIcon sx={{marginRight: '10px'}}/>
        <Box>
          <Typography component='h6' variant='h6' fontWeight={600}  sx={{
            fontSize: '14px', // Change the font family
            color: 'black',
            height: '1.5em',
            paddingRight: '5px'
          }}>
            Billing Frequency
          </Typography>
          <Typography component='h6' variant='h6' fontWeight={400}  sx={{
            fontSize: '14px', // Change the font family
            color: 'black',
            height: '1.5em',
            paddingTop: '5px',
            paddingRight: '5px',
            textTransform: 'capitalize'
          }}>
            {freqStr}
          </Typography>
        </Box>
      </Box>
    </>
  }

  let handlePurchaseCredits = (event) => {
    event.preventDefault()
    setStripeItem("basic_10_pack")
    setStripeOpen(true)
  }

  let handlePurchaseSubscription = (event) => {
    event.preventDefault()
    setStripeItem("basic_soopra_subscription")
    setStripeOpen(true)
  }

  let handleCancelSubscription = (event: any) => {
    event.preventDefault();
    let cancellationData = {};
    cancellationData['title'] = "We're sorry to see you go";
    cancellationData['message'] = 'To cancel your subscription, email Soopra at <a href="mailto:support@soopra.ai"><u>support@soopra.ai</u></a>'
    cancellationData['imgSrc'] = timeoutImage;
    cancellationData['buttons'] = [
      {
        'text': "Close",
        'action': () => setShowGenericModal(false),
        'variant': 'contained'
      }
    ];
    setGenericModalData(cancellationData);
    setShowGenericModal(true);
  }

  let PurchaseButtons = () => {
    if (isSubscribed) {
      return <>
        <Button
          className={"mobile-fullwidth mobile-nomargin"}
          id={`button-purchase-credits`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            marginRight: '4px',
            borderRadius:'6px',
            width: '50%',
            fontSize: '14px',
            lineHeight: 1,
          }}
          fullWidth
          onClick={handlePurchaseCredits}
        >
          Buy 10 Credits
        </Button>
        <Button
          className={"mobile-fullwidth mobile-margintop"}
          id={`button-cancel-subscription`}
          variant="contained"
          onClick={handleCancelSubscription}
          sx={{
            backgroundColor: 'white !important',
            border: '1px solid #009CDB',
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            flex: 1,
            marginLeft: '4px' ,
            color: grey[600] + ' !important',
            borderColor: grey[600] + ' !important',
            width: '50%',
            fontSize: '14px',
            lineHeight: 1,
          }}
          fullWidth>
          Cancel Subscription
        </Button>
      </>
    } else {
      return <>
        <Button
          id={`button-purchase-subscription`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            width: '50%',
            fontSize: '14px'
          }}
          fullWidth
          onClick={handlePurchaseSubscription}
        >
          Subscribe
        </Button>
        <Button
          id={`button-cancel-subscription`}
          variant="contained"
          disabled
          sx={{
            backgroundColor: 'white !important',
            border: '1px solid #009CDB',
            height: '32px!important',
            padding: '6px 0px !important',
            borderRadius:'6px',
            flex: 1,
            marginLeft: '8px' ,
            marginRight: '5px',
            color: grey[600] + '!important',
            borderColor: grey[600] + '!important',
            width: '50%',
            fontSize: '14px'
          }}
          fullWidth>
          Cancel Subscription
        </Button>
      </>
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      paddingTop: '10px',
      paddingBottom: '10px',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      fontSize: 16,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  function getFormattedDate(date: Date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  if (!enableMonetization && !(enableCourses || authUser?.beta_tester || authLoading)) {
    // redirect to creators page
    return <Navigate to="/creators" />
  }

  return (
    <>
    {
      <Box sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Box
          className={"my-subscriptions-box"}
          sx={{
            border: '1px solid gray',
            borderRadius: '5px',
            margin: '10px',
            padding: '25px',
            width: '100%',
            maxWidth: '1000px'
          }}
        >
          {enableMonetization && <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid gray',
            paddingBottom: '25px'
          }}>
            <Typography component='h6' variant='h6' fontWeight={600}  sx={{
              fontSize: '18px', // Change the font family
              color: 'black',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              height: '1.5em',
            }}>
              My Subscription
            </Typography>
          </Box> }
          {enableMonetization && <Box
            className={"mobile-column"}
            sx={{
              paddingTop: '25px',
              paddingBottom: '25px',
              borderBottom: '1px solid gray',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <SubscriptionStatus />
          </Box>}
          {enableMonetization && <Box
            className={"mobile-column"}
            sx={{
              paddingTop: '25px',
              paddingBottom: '25px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <PaymentMethod />
          </Box> }
          {enableMonetization && <Box
            className={"mobile-column mobile-fullwidth mobile-nopaddingtop"}
            sx={{
              paddingTop: '25px',
              paddingBottom: '25px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <PurchaseButtons />
          </Box>}
          <Box>
            <Typography component='h6' variant='h6' fontWeight={600}  sx={{
              fontSize: '18px', // Change the font family
              color: 'black',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              height: '1.5em',
              marginBottom: '20px',
            }}>
              {(enableMonetization ? "Subscription & " : "") + "Purchase History"}
            </Typography>
            <TableContainer>
              <Table size="small" aria-label="a dense table" className={"payments-table"}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell align="left">Item</StyledTableCell>
                    <StyledTableCell align="left">Credits</StyledTableCell>
                    <StyledTableCell align="left">Price</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentsHistory.map((row) => {
                    let date = new Date(row.createdAt);

                    return (
                      <TableRow
                        key={row.docId}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {getFormattedDate(date)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.course_info ?
                            <Link
                              id={`href-course-learn-${row?.course_info?.course_id}`}
                              to={`/learn/${row?.course_info?.course_id}`}
                              state={{courseItem: row?.course_info}}
                              style={{
                                color: '#2b6a9b',
                                textDecoration: 'underline',
                              }}
                            >
                              {row?.course_info?.name}
                            </Link> :
                            row.product_name ?
                              (row.product_redirect_link ?
                                <Link
                                  id={`href-premium-product`}
                                  to={row.product_redirect_link || null}
                                  style={{
                                    color: '#2b6a9b',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  {row.product_name}
                                </Link> :
                                row.product_name
                              ):
                              STRIPE_PRODUCT_DISPLAY_NAMES[row.item] || ""
                          }
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.credits}</StyledTableCell>
                        <StyledTableCell align="left">${row.price}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              { isLoading ?
                  <Box
                    display='flex'
                    width='100%'
                    padding='1.5rem'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <CircularProgress size={30} />
                  </Box> :
                  paymentsHistory.length === 0 ?
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 1,
                      }}
                    >
                      <Typography
                        component='h6'
                        variant='h6'
                        fontWeight={400}
                        sx={{
                          fontSize: '14px',
                          color: 'gray',
                        }}
                      >
                        No purchase history found
                      </Typography>
                    </Box> :
                    <></>
              }
              <div className="custom-bottom" ref={bottom}/>
            </TableContainer>
          </Box>
        </Box>
        <StripeCheckoutForm item={stripeItem} isOpen={stripeOpen} setIsOpen={setStripeOpen}/>
        <GenericModal showGenericModal={showGenericModal} setShowGenericModal={setShowGenericModal} data={genericModalData}/>
      </Box>
    }
  </>
  )
}

export default Purchases;
